/*
 * @Author: 张圳
 * @Description:
 * @Date: 2023-05-10 09:19:32
 * @LastEditTime: 2023-05-19 15:16:31
 * @FilePath: \business\src\api\interface.ts
 */
// 金大盘价
import { get, post } from './http'
// 分页查询所有
const path = '/business'
const API: any = {
  // glodSelectAlls: path + '/goldEntry/selectAlls', // 分页查询列表
  glodSelectAll: path + '/goldEntry/selectAll', // 查询数据
  glodAdd: path + '/goldEntry/insert', // 金大盘价新增
  glodEdit: path + '/goldEntry/update', // 金大盘价编辑
  glodDel: path + '/goldEntry/delete', // 获取所有菜单
  glodStartOrStop: path + '/goldEntry/openOrClose', // 金大盘价启用停用
  getShopList: path + '/shop/shopList', // 门店管理列表查询
  getShopListInsert: path + '/shop/insert', // 门店管理添加
  getShopListDelete: path + '/shop/delete', // 门店管理删除
  getShopListDetail: path + '/shop/detail', // 门店管理删除
  getShopListUpdate: path + '/shop/update', // 门店管理修改
  getUpdateState: path + '/order/updateByOrderVo', // 修改订单状态
  getReceiveBank: path + '/order/queryAmountByOrderId', // 获取开户行
  getCurrentUser: path + '/user/getListByUserName', // 获取当前用户
  getDictionaryByType: path + '/dictionaries/list', // 通过字典类型查询字典值
  getPanelPieEcharts: path + '/businessPanel/dorProportion', // 控制面板饼图
  getPanelBarEcharts: path + '/businessPanel/barChart', // 控制面板条状图
  getPanelQueryData: path + '/businessPanel/selectOrderNum', // 控制面板查询,
  getOrderList: path + '/order/list' // 订单列表
}

// export const glodSelectAllsApi = () => post(API.glodSelectAlls)
export const glodSelectAllApi = (data: any) => post(API.glodSelectAll, data, '')
export const glodAddApi = (data: any) => post(API.glodAdd, data, '')
export const glodEditApi = (data: any) => post(API.glodEdit, data, '')
export const glodDelApi = (data: any) => post(API.glodDel, data, '')
export const glodStartOrStopApi = (data: any) => post(API.glodStartOrStop, data, '')
export const getShopListApi = (data: any) => post(API.getShopList, data, '')
export const getShopListInsertApi = (data: any) => post(API.getShopListInsert, data, '')
export const getShopListDeleteApi = (data: any) => post(API.getShopListDelete, data, '')
export const getShopListDetailApi = (data: any) => post(API.getShopListDetail, data, '')
export const getShopListUpdateApi = (data: any) => post(API.getShopListUpdate, data, '')
export const getUpdateStateApi = (data:any) => post(API.getUpdateState, data, '')
export const getReceiveBankApi = (data:any) => post(API.getReceiveBank, data, '')
export const getCurrentUserApi = () => get(API.getCurrentUser)
export const getDictionaryByTypeApi = (data:any) => get(API.getDictionaryByType, data)
export const getPanelPieEchartsApi = (data:any) => post(API.getPanelPieEcharts, data, '')
export const getPanelBarEchartsApi = (data:any) => post(API.getPanelBarEcharts, data, '')
export const getPanelQueryDataApi = () => get(API.getPanelQueryData)
export const getOrderListApi = (data:any) => post(API.getOrderList, data, '')
