import { get, post } from './http'

const API = {
  getUserList: '/business/user/list', // 用户列表
  userDelete: '/business/user/delete', // 用户批量删除
  userDetail: '/business/user/detail', // 用户详情
  orderList: '/business/order/list', // 订单列表
  orderDelete: '/business/order/delete', // 订单批量删除
  orderDetail: '/business/order/details' // 订单详情
}

export const getUserListApi = data => post(API.getUserList, data)// 用户列表
export const userDeleteApi = data => post(API.userDelete, data)// 用户删除
export const userDetailApi = data => post(API.userDetail, data)// 用户详情
export const orderListApi = data => post(API.orderList, data)// 订单列表
export const orderDeleteApi = data => post(API.orderDelete, data)// 订单批量删除
export const orderDetailApi = data => post(API.orderDetail, data)// 订单详情

// export const getPermissionApi = (data: any) => get(API.getPermission, data)
